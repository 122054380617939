import request from '@/api/request'


/******* 广告位 ******/
export function querySlot(data) {
  return request({ url: 'slot/query', method: 'post', data: data })
}
export function editSlot(data) {
  return request({ url: 'slot/edit', method: 'post', data: data })
}
export function deleteSlot(params) {
  return request({ url: 'slot/del', method: 'get', params: params })
}
export function commitSlotConfig(data) {
  return request({ url: 'slot/config', method: 'post', data: data })
}
export function getSlotConfig(params) {
  return request({ url: 'slot/config', method: 'get', params: params })
}
export function getAllSlot() {
  return request({ url: 'slot/all', method: 'get', })
}
export function auditOp(data) {
  return request({ url: 'slot/audit', method: 'post', data:data})
}
export function deleteSlotPkg(params) {
  return request({ url: 'slot/pkgConfig/del', method: 'get', params: params })
}

export function publicSlotConfig() {
  return request({ url: 'slot/pub', method: 'get'})
}


/******* 媒体 ******/
export function getAllApp() {
  return request({ url: 'app', method: 'get', })
}
export function queryApp(data) {
  return request({ url: 'app/query', method: 'post', data: data })
}
export function editApp(data) {
  return request({ url: 'app/edit', method: 'post', data: data })
}
export function deleteApp(params) {
  return request({ url: 'app/del', method: 'get', params: params })
}

/******* 渠道 ******/
export function queryChl(data) {
  return request({ url: 'chl/query', method: 'post', data: data })
}
export function editChl(data) {
  return request({ url: 'chl/edit', method: 'post', data: data })
}
export function deleteChl(params) {
  return request({ url: 'chl/del', method: 'get', params: params })
}
export function getAllChl() {
  return request({ url: 'chl', method: 'get', })
}


/******* 平台 ******/
export function getAllNetwork(data) {
  return request({ url: 'network/all', method: 'get', data})
}
export function getAllNetworkLibrary() {
  return request({ url: 'networklibrary/all', method: 'get', })
}
export function editNetwork(data){
  return request({ url: 'network/modify', method: 'post', data: data })
}
export function queryetwork(data) {
  return request({ url: 'network/query', method: 'post', data:data})
}
export function queryAllNetworkByTenantId(data) {
  return request({ url: 'network/allNel', method: 'post', data:data})
}

/******* 平台KEY ******/
export function queryNKey(data) {
  return request({ url: 'nkey/query', method: 'post', data: data })
}
export function editNkey(data) {
  return request({ url: 'nkey/edit', method: 'post', data: data })
}
export function checkNkey(data) {
  return request({ url: 'nkey/check', method: 'post', data: data })
}
export function deleteNkey(params) {
  return request({ url: 'nkey/del', method: 'get', params: params })
}
export function getAllNkey() {
  return request({ url: 'nkey/all', method: 'get' })
}
export function getSlotSettingsByNKey(params) {
  return request({ url: 'nkey/config', method: 'get' ,params: params})
}
export function publicNkeyConfig() {
  return request({ url: 'nkey/pub', method: 'get'})
}
export function getTestAds() {
  return request({ url: 'nkey/testAds', method: 'get'})
}


/******* 其它 ******/
export function adTest(data, url, ver) {
  return request({ url: 'adtest/' + url + "/" + ver, method: 'post', data: data })
}
export function getMedia(params) {
  return request({ url: 'chl/medias', method: 'get', params: params })
}



/******* 财务结算 ******/
export function getFinProgress(params) {
  return request({ url: 'fina/progress', method: 'get', params: params })
}
export function getFinaReport(data) {
  return request({ url: 'fina/report', method: 'post', data: data })
}
export function exportFinaReport(data) {
  return request({ url: 'fina/report/export', method: 'post', data: data, responseType: "blob" })
}
export function getFinaSspReport(data) {
  return request({ url: 'fina/report-ssp', method: 'post', data: data })
}
export function exportFinaSspReport(data) {
  return request({ url: 'fina/report-ssp/export', method: 'post', data: data, responseType: "blob" })
}
export function getProfitReport(data) {
  return request({ url: 'fina/report-profit', method: 'post', data: data })
}
export function exportProfitReport(data) {
  return request({ url: 'fina/report-profit/export', method: 'post', data: data, responseType: "blob" })
}
/****  DSP Settle ****/
export function getDspSettleData(params) {
  return request({ url: 'fina/dsp/data', method: 'get', params: params })
}

export function commitDspSettleData(data) {
  return request({ url: 'fina/dsp/settle', method: 'post', data: data })
}
export function clearDspSettleData(data) {
  return request({ url: 'fina/dsp/clear', method: 'post', data: data })
}

export function settleExcelCommit(data) {
  return request({ url: 'fina/dsp/import', method: 'post', data: data, timeout: 60000 })
}
export function settleExcelDownload(data) {
  return request({ url: 'fina/dsp/download', method: 'post', data: data , responseType: "blob" })
}

export function getDspCutData(data) {
  return request({ url: 'fina/cut/query', method: 'post',data:data})
}
export function cutExcelCommit(data) {
  return request({ url: 'fina/cut/import', method: 'post', data: data, timeout: 60000 })
}
export function deleteCutData(params) {
  return request({ url: 'fina/cut/delete', method: 'get', params: params })
}
export function createConfirmOrder(data) {
  return request({ url: 'fina/confirm/create', method: 'post', data: data })
}
export function confirmExcelDownload(data) {
  return request({ url: 'fina/confirm/export', method: 'post', data: data , responseType: "blob" })
}

/****  SSP Settle ****/
export function getSspSettleData(params) {
  return request({ url: 'fina/ssp/data', method: 'get', params: params })
}
export function getSspSettleDataV2(params) {
  return request({ url: 'fina/ssp/datav2', method: 'get', params: params })
}
export function commitSspSettleData(data) {
  return request({ url: 'fina/ssp/settle', method: 'post', data: data })
}
export function clearSspSettleData(data) {
  return request({ url: 'fina/ssp/clear', method: 'get', params: data })
}
export function getSspSettleDetail(params) {
  return request({ url: 'fina/ssp/detail', method: 'get', params: params })
}
export function getSspSettleDataByNkey(params) {
  return request({ url: 'fina/ssp/nkey_data', method: 'get', params: params })
}
export function exportDspSettleReport(data) {
  return request({ url: 'fina/dsp/data/export', method: 'post', data: data, responseType: "blob" })
}
export function exportSspSettleReport(data) {
  return request({ url: 'fina/ssp/data/export', method: 'post', data: data, responseType: "blob" })
}
export function importSspSettleData(data) {
  return request({ url: 'fina/ssp/import', method: 'post',data:data})
}
export function updateSspSettleStatus(data) {
  return request({ url: 'fina/ssp/update/status', method: 'post', data:new URLSearchParams(data )})
}
export function updateSspSettleStatusv2(data) {
  return request({ url: 'fina/ssp/update/statusv2', method: 'post', data:new URLSearchParams(data )})
}

export function batchClearSspSettleData(data) {
  return request({ url: 'fina/ssp/batchclear', method: 'post', data:new URLSearchParams(data )})
}
/***** 媒体月度结算 */
export function getSspMonthSettleData(params) {
  return request({ url: 'fina/ssp/settle_data', method: 'get', params: params })
}
export function getSspMonthSettleDetailData(params) {
  return request({ url: 'fina/ssp/settledetail_data', method: 'get', params: params })
}

export function getConfirmOrder(data) {
  return request({ url: 'fina/ssp/confirm_order', method: 'post', data: data })
}

export function getConfirmDetailOrder(data) {
  return request({ url: 'fina/ssp/confirm', method: 'post', data: data })
}
/***** 统计报表 */

export function statDateSsp(data) {
  return request({ url: 'stat/date-ssp', method: 'post', data: data })
}
export function statDateDsp(data) {
  return request({ url: 'stat/date-dsp', method: 'post', data: data })
}
export function statSlot(data) {
  return request({ url: 'stat/slot', method: 'post', data: data })
}
export function statSlotNKey(data) {
  return request({ url: 'stat/slot/nkey', method: 'post', data: data })
}
export function statNetwork(data) {
  return request({ url: 'stat/network', method: 'post', data: data })
}
export function statNKey(data) {
  return request({ url: 'stat/nkey', method: 'post', data: data })
}

/**限流请求数统计 */
export function statLimit(data) {
  return request({ url: 'limit/query', method: 'post', data: data })
}
//限流统计导出
export function exportLimitStat(data) {
  return request({ url: 'limit/export', method: 'post', data: data, responseType: "blob" })
}

//导出
export function exportStatDateSsp(data) {
  return request({ url: 'stat/date-ssp/export', method: 'post', data: data, responseType: "blob" })
}
export function exportStatDateDsp(data) {
  return request({ url: 'stat/date-dsp/export', method: 'post', data: data, responseType: "blob" })
}
export function exportStatSlot(data) {
  return request({ url: 'stat/slot/export', method: 'post', data: data , responseType: "blob"})
}
export function exportStatNetwork(data) {
  return request({ url: 'stat/network/export', method: 'post', data: data, responseType: "blob" })
}
export function exportStatNKey(data) {
  return request({ url: 'stat/nkey/export', method: 'post', data: data , responseType: "blob"})
}
//实时统计
export function statRTSLot(data) {
  return request({ url: 'stat/rt/slot', method: 'post', data: data })
}
export function statRTNkey(data) {
  return request({ url: 'stat/rt/nkey', method: 'post', data: data })
}
export function statRTDateSsp(data) {
  return request({ url: 'stat/rt/date-ssp', method: 'post', data: data })
}
export function statRTDateDsp(data) {
  return request({ url: 'stat/rt/date-dsp', method: 'post', data: data })
}
//实时统计
export function statLTSSLot(data) {
  return request({ url: 'stat/lts/slot', method: 'post', data: data })
}
export function statLTSNkey(data) {
  return request({ url: 'stat/lts/nkey', method: 'post', data: data })
}
export function statLTSDateSsp(data) {
  return request({ url: 'stat/lts/date-ssp', method: 'post', data: data })
}
export function statLTSDateDsp(data) {
  return request({ url: 'stat/lts/date-dsp', method: 'post', data: data })
}
//20240911 添加按媒体汇总分页方法、按广告主汇总分页方法
export function statLTSSLotPage(data) {
  return request({ url: 'stat/lts/page-slot', method: 'post', data: data })
}
export function statLTSNkeyPage(data) {
  return request({ url: 'stat/lts/page-nkey', method: 'post', data: data })
}


//实时统计-dsp异常详细信息
export function statLTSExceptionDsp(data) {
  return request({ url: 'stat/lts/exception-dsp', method: 'post', data: data })
}
//实时统计-ssp异常详细信息
export function statLTSExceptionSsp(data) {
  return request({ url: 'stat/lts/exception-ssp', method: 'post', data: data })
}
//实时统计-dp详细信息
export function statLTSDpSsp(data) {
  return request({ url: 'stat/lts/dp', method: 'post', data: data })
}

/***** 系统管理 */
export function changePassword(data) {
  return request({ url: 'user/password', method: 'post', data: data })
}
export function editUser(data) {
  return request({ url: 'user/edit', method: 'post', data: data })
}
export function deleteUser(userId) {
  return request({ url: 'user/del', method: 'get', params: {userId} })
}
export function getAllUser(data) {
  return request({ url: 'user', method: 'post', data: data })
}
export function getAllLogs(data) {
  return request({ url: 'login/log', method: 'post', data: data })
}

/***** 运维监控 */
function monitorServer(params) {
  return request({ url: 'monitor/cvm', method: 'get', params: params })
}
/******* 租户 ******/
export function getAllTenant() {
  return request({ url: 'tenant/all', method: 'get', })
}
/******* 获取华为云免密登录的url ******/
export function getLtsAdLogUrl() {
  return request({ url: '/lts/login/getLoginUrl', method: 'post', })
}
/******* 获取华为云免密登录的url sdk使用******/
export function getSdkAdLogUrl() {
  return request({ url: '/lts/login/getSdkLoginUrl', method: 'post', })
}
/******* 根据广告位获取包名列表 ******/
export function getLtsPkgList(data) {
  return request({ url: 'stat/lts/pkg', method: 'post', data: data})
}

/******* publisher ******/
/******* 广告位 ******/
export function queryPublisherSlot(data) {
  return request({ url: 'publisher/slot/query', method: 'post', data: data })
}
export function editPublisherSlot(data) {
  return request({ url: 'publisher/slot/edit', method: 'post', data: data })
}
export function deletePublisherSlot(params) {
  return request({ url: 'publisher/slot/del', method: 'get', params: params })
}
export function getPublisherAllSlot() {
  return request({ url: 'publisher/slot', method: 'get', })
}
export function checkSlot(data) {
  return request({ url: 'publisher/slot/nameCheck', method: 'post', data: data })
}
/******* 媒体 ******/
export function getPublisherAllApp() {
  return request({ url: 'publisher/app', method: 'get', })
}
export function queryPublisherApp(data) {
  return request({ url: 'publisher/app/query', method: 'post', data: data })
}
export function editPublisherApp(data) {
  return request({ url: 'publisher/app/edit', method: 'post', data: data })
}
export function deletePublisherApp(params) {
  return request({ url: 'publisher/app/del', method: 'get', params: params })
}
export function getPublisherSlotByApp(params) {
  return request({ url: 'publisher/app/slot', method: 'get', params: params })
}
export function getCurrentChannel() {
  return request({ url: 'publisher/channel', method: 'get' })
}
/******* 报表 ******/
export function getPublisherFinaReport(data) {
  return request({ url: 'publisher/report', method: 'post', data: data })
}
export function exportPublisherFinaReport(data) {
  return request({ url: 'publisher/report/export', method: 'post', data: data, responseType: "blob" })
}

/******* 账户 ******/
export function getCurrentUserInfo() {
  return request({ url: 'publisher/account/info', method: 'get' })
}
export function saveAccount(data) {
  return request({ url: 'publisher/account/edit', method: 'post', data: data })
}
export function registerAccount(data) {
  return request({ url: 'publisher/account/register', method: 'post', data: data })
}
export function getlogs(data) {
  return request({ url: 'publisher/login/log', method: 'post',data:data })
}

/******* sdk ******/
export function getSdkInfo(domain) {
  return request({ url: 'publisher/sdk', method: 'get',params: {domain} })
}

/******* publisher ******/


/******* SDKMgt ******/
export function getSdkSetting() {
  return request({ url: 'sdk/setting', method: 'get' })
}
export function saveSdkSetting(data) {
  return request({ url: 'sdk/setting', method: 'post',data:data })
}
export function getSdkList(data) {
  return request({ url: 'sdk/list', method: 'post',data:data })
}
export function saveSdk(data) {
  return request({ url: 'sdk/save', method: 'post',data:data })
}
export function deleteSdk(id) {
  return request({ url: 'sdk/del', method: 'get',params:{id} })
}
export function pushSdk(id) {
  return request({ url: 'sdk/push', method: 'get',params:{id} })
}

export function apiDoc(id) {
  return request({ url: 'doc', method: 'get'})
}


/******* 自主广告 ******/
export function adList(data) {
  return request({ url: 'ad/query', method: 'post',data:data })
}
export function saveAd(data) {
  return request({ url: 'ad/save', method: 'post',data:data })
}
export function adDetail(id) {
  return request({ url: 'ad/detail', method: 'get',params:{id} })
}
export function deleteAd(id) {
  return request({ url: 'ad/del', method: 'get',params:{id} })
}
export function copyAd(id) {
  return request({ url: 'ad/copy', method: 'get',params:{id} })
}
export function uploadImg(data) {
  return request({ url: 'ad/upload', method: 'post',data:data })
}
export function getId() {
  return request({ url: 'ad/getId', method: 'get' })
}
/******* 自主广告 ******/